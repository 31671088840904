import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, NavParams } from '@ionic/angular';

export interface ActionDropdownType {
  label: string;
  icon?: string;
  selected?: boolean;
  metadata?: any;
  id?: string;
}

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, ScrollingModule],
  selector: 'app-action-dropdown',
  templateUrl: './action-dropdown.component.html',
  styleUrls: ['./action-dropdown.component.scss'],
})
export class ActionDropdownComponent {
  options: ActionDropdownType[] = [];
  itemClass?: string;
  checkbox: boolean;
  multiCheckbox: boolean;
  callback: (index: number, label: string, id?: string, metadata?: any) => {};
  onCloseCallback: () => {};

  constructor(navParmas: NavParams) {
    this.options = navParmas.get('options');
    this.checkbox = navParmas.get('checkbox');

    if (this.checkbox) {
      this.options.map((opt) => {
        opt.icon = opt.selected ? 'checkbox' : 'square-outline';
      });
    }
  }

  didClick(index: number, label: string, id?: string, metadata?: any) {
    if (this.checkbox) {
      const option = this.options[index];

      option.selected = !option.selected;
      option.icon = option.selected ? 'checkbox' : 'square-outline';

      this.options.splice(index, 1, option);
    }

    this.callback(index, label, id, metadata);
  }

  onClose() {
    this.onCloseCallback();
  }
}
